import React from 'react';
import {
  Container, Row, Col, Card,
} from 'react-bootstrap';
import { IndustriesData } from '../../../data/solutions-db';

export default function IndustriesCatered() {
  return (
    <section className="pb-5">
      <Container>
        <Row>
          <h2 className="text-center my-5">
            {IndustriesData[0].heading}
            <span className="infenox-text-highlight prtn-line" />
          </h2>
          {IndustriesData[0].data.map(({
            id, heading, image, text,
          }) => (
            <Col className="col-6 col-sm-4 col-md-3 gap_ind2" key={id}>
              <Card className="industries-catered-card">
                <img src={image} alt={heading} className="industries-catered-img" />
                <Card.Body>
                  <Card.Title className="fs-6">{heading}</Card.Title>
                  <Card.Text className="custom-text-justify">{text}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

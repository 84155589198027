import React from 'react';
import {
  Container, Row, Col, Card,
} from 'react-bootstrap';
import { PillarsData } from '../../../data/solutions-db';

export default function Pillars() {
  return (
    <section className="py-5 pillar">
      <Container>
        <Row>
          <h2 className="pb-5 text-center">
            {PillarsData[0].heading}
            {' '}
            <span className="infenox-text-highlight size-line" />
          </h2>
          {PillarsData[0].data.map(({
            id, heading, data, image,
          }) => (
            <Col key={id} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
              <Card className="h-100 pillar-card">
                <img src={image} alt={heading} className="pillar-card-image" />
                <Card.Body>
                  <Card.Title className="pillar-card-heading">{heading}</Card.Title>
                  <Card.Text>{data}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

import React from 'react';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import { ServicesOffered } from '../../../data/solutions-db';

export default function SolutionHeader() {
  return (
    <section className="solutions-tab-section">
      <Container>
        <Row className="solutions-tab-header">
          <Col><h2 className="head-solution">{ServicesOffered[0].heading1}</h2></Col>
        </Row>
      </Container>
    </section>

  );
}

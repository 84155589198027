import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import SolutionHeader from './components/solutions/solutions-header';
import IndustriesCatered from './components/solutions/industries-catered';
import ExecutionMethodology from './components/solutions/execution-methodology';
import './components/solutions/styles.scss';
import EngagementModel from './components/solutions/our-engagement-model';
import Pillars from './components/solutions/pillars';
import ServicesTab from './components/solutions/services-tab';
import SolutionTab from './components/solutions/solutions-tab';

function Solutions() {
  return (
    <Layout>
      <Seo title="Solutions" />
      <SolutionHeader />
      <ServicesTab />
      <SolutionTab />
      <EngagementModel />
      <Pillars />
      <IndustriesCatered />
      <ExecutionMethodology />
    </Layout>
  );
}

export default Solutions;
